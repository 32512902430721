window.search = function search(value) {
  const query = value;
  window.location.hash = encodeURIComponent(query);

  const allRooms = document.getElementsByClassName("room");
  for (let room of allRooms) {
    room.style.backgroundColor = null;
  }

  const allPersons = document.getElementsByClassName("person");
  for (let person of allPersons) {
    person.parentElement.className = person.parentElement.className.replace(
      "chair--focused",
      ""
    );
  }

  if (!query) return;

  const roomResults = [].slice
    .call(document.getElementsByClassName("name"))
    .map((div) => ({ parent: div.parentElement, label: div.innerHTML }))
    .filter((data) => new RegExp(query, "i").test(data.label));

  roomResults.forEach(
    (res) => (res.parent.style.backgroundColor = "goldenrod")
  );

  const personResults = [].slice
    .call(document.getElementsByClassName("person"))
    .map((div) => ({ parent: div.parentElement, label: div.innerHTML }))
    .filter((data) => new RegExp(query, "i").test(data.label));
  personResults.forEach((res) => {
    res.parent.className = res.parent.className + " chair--focused";
  });
};
